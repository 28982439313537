import React from "react"

export const Turniere = () => (
  <table className="mt-4 table">
    <thead className="thead">
      <tr>
        <th scope="col" className="th">
          Name
        </th>
        <th scope="col" className="th">
          Datum
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-700 dark:divide-gray-600">
      <tr>
        <td className="td">Merseburg</td>
        <td className="td">12./13.10.2024</td>
      </tr>
      <tr>
        <td className="td">Wangersen</td>
        <td className="td">26.10.2024</td>
      </tr>
    </tbody>
  </table>
)
