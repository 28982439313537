export interface Matchday {
  date: Date
  host: string
  enemies: string[]
}

export interface MiscMatch {
  dateFrom: Date
  dateTo?: Date
  name: string
  host: string
}

// export const ahlhorn = "Ahlhorner SV"
// export const armstorf = "SV Armstorf"
// export const brettorf = "TV Brettorf 2"
// export const dudenbuettel = "SV Düdenbüttel"
export const empelde = "TuS Empelde"
// export const ohligs = "Ohligser TV"
export const spenge = "TuS Spenge"
export const vorsfelde = "MTV Vorsfelde"
export const wangersen = "MTV Wangersen"
export const hannover = "TK Hannover"
export const leichlingen = "Leichlinger TV 2"
export const bardowick = "TSV Bardowick"
// export const coesfeld = "SG Coesfeld 09"
// export const leverkusen = "Bayer 04 Leverkusen"
// export const ruschwedel = "SV Ruschwedel"
// export const abbenseth = "TSV Abbenseth"
// export const moslesfehn = "SV Moslesfehn"
export const burgdorf = "TSV Burgdorf"
// export const hammah = "MTV Hammah"
// export const hagen = "TSV Hagen 1860 2"
export const hamburg = "ETV Hamburg"

export const matchdaysMannschaft1Halle2425: Matchday[] = [
  {
    date: new Date(2024, 10, 16, 15, 0),
    enemies: [burgdorf, bardowick],
    host: vorsfelde,
  },
  {
    date: new Date(2024, 10, 30, 15, 0),
    enemies: [empelde, leichlingen],
    host: empelde,
  },
  {
    date: new Date(2024, 11, 7, 15, 0),
    enemies: [hamburg, wangersen],
    host: wangersen,
  },
  {
    date: new Date(2024, 11, 14, 13, 0),
    enemies: [hannover, spenge],
    host: hannover,
  },
  {
    date: new Date(2025, 0, 11, 15, 0),
    enemies: [bardowick, burgdorf],
    host: bardowick,
  },
  {
    date: new Date(2025, 0, 18, 15, 0),
    enemies: [hamburg, wangersen],
    host: hamburg,
  },
  {
    date: new Date(2025, 0, 25, 15, 0),
    enemies: [empelde, leichlingen],
    host: leichlingen,
  },
  {
    date: new Date(2025, 1, 1, 15, 0),
    enemies: [spenge, hannover],
    host: vorsfelde,
  },
]

// export const gliesmarode = "SCE Gliesmarode"
// export const vienenburg = "MTV Vienenburg"
// export const thiede = "FC Viktoria Thiede"
// export const vorsfelde2 = "MTV Vorsfelde 2"
// export const schwiegershausen = "TSV Schwiegershausen"
// export const salzgitter = "MTV Salzgitter"

// export const matchdaysMannschaft2Feld23: Matchday[] = [
//   {
//     date: new Date(2023, 4, 14, 10, 0),
//     enemies: [schwiegershausen, gliesmarode, salzgitter],
//     host: gliesmarode,
//   },
//   {
//     date: new Date(2023, 5, 24, 10, 0),
//     enemies: [schwiegershausen, gliesmarode, salzgitter],
//     host: thiede,
//   },
// ]

// export const hohenklingen = "TV Hohenklingen"

// export const miscMatchesMaenner35: MiscMatch[] = [
// {
//   dateFrom: new Date(2023, 0, 22),
//   name: "Landesmeisterschaften",
//   host: vorsfelde
// },
// {
//   dateFrom: new Date(2023, 1, 11),
//   name: "Regionalmeisterschaft Nord",
//   host: dudenbuettel
// },
// {
//   dateFrom: new Date(2023, 2, 25),
//   dateTo: new Date(2023, 2, 26),
//   name: "Deutsche Meisterschaft",
//   host: hohenklingen
// },
// ]
